import { useState, useContext, useCallback, useEffect, useRef } from "react";
import { DAppContext } from "./context";
import { Toaster } from "react-hot-toast";
import Banner from "./banner.png";
import Gifer from "./goblin.gif";
import Opensea from "./opensea.svg";
import Mining from "./mining.mp3";
import { ReactComponent as Twitter } from "./twitter.svg";

function App() {
  const [count, setCount] = useState(1);
  const audioRef = useRef(null);
  const [mintedCount, setMintedCount] = useState(0);
  const {
    loading,
    connectBrowserWallet,
    mint,
    userData,
    transactionHash,
    contractDetails,
    isClaimed,
  } = useContext(DAppContext);

  const handleCounter = (e) => {
    const { dataset } = e.currentTarget;
    const { pos } = dataset;

    if (pos === "plus" && count < 10) {
      setCount(() => count + 1);
      return;
    }

    if (count - 1 !== 0) {
      setCount(() => count - 1);
    }
  };

  const connectWallet = () => {
    audioRef.current.play();
    audioRef.current.volume = 0.5;
    connectBrowserWallet();
  };
  
  const handleCustomMint = useCallback(() => {
    mint(count);
  }, [count, userData, contractDetails]);

  useEffect(() => {
    if (!!contractDetails) {
      setMintedCount(parseInt(Number(contractDetails.alreadyMinted)));
    }
  }, [contractDetails]);
  return (
    <>
      <audio ref={audioRef} playsInline autoPlay loop>
        <source src={Mining} type="audio/mpeg" />
      </audio>
      <section
        className="greed-container"
        style={{ backgroundImage: `url(${Banner})` }}
      >
        <div className="greed-links">
          <a
            href="https://opensea.io/collection/bingo-goblins"
            target="_blank"
            rel="noreferrer"
            className="greed-link-item"
          >
            <img src={Opensea} alt="Opensea Link" />
          </a>
          <a
            href="https://twitter.com/goblinsbingo"
            target="_blank"
            rel="noreferrer"
            className="greed-link-item greed-twitter"
          >
            <Twitter />
          </a>
        </div>

        <div className="greed-form">
          <h1 className="f-shadow">
            BBIiinGgoo GoblLiinz Are heErre uu LlUuuckKyy
          </h1>
          <div className="greed-wrapper">
            <img src={Gifer} alt="Gifer" />
            <div className="greed-minter">
              <h2>Free mint 2222</h2>
              {!!userData ? (
                <>
                  {!!contractDetails && (
                    <p className="counter">{mintedCount} / 7777</p>
                  )}
                  <div className="greed-form-counter">
                    
                      <button
                        onClick={handleCounter}
                        type="button"
                        className="greed-btn"
                        data-pos="minus"
                        disabled={loading || isClaimed}
                      >
                        -
                      </button>

                    <span> {count} </span>
                    
                      <button
                        onClick={handleCounter}
                        type="button"
                        className="greed-btn"
                        data-pos="plus"
                        disabled={loading || isClaimed}
                      >
                        +
                      </button>
                    
                    <button
                      onClick={handleCustomMint}
                      type="button"
                      className="claim-btn"
                      disabled={loading || isClaimed}
                    >
                      Mint
                    </button>
                  </div>
                  
                    <div className="greed-claimers">
                      <button
                        onClick={() => mint(3)}
                        type="button"
                        className="claim-btn"
                        disabled={loading || isClaimed}
                      >
                        Mint 3
                      </button>
                      <button
                        onClick={() => mint(5)}
                        type="button"
                        className="claim-btn"
                        disabled={loading || isClaimed}
                      >
                        Mint 5
                      </button>
                      <button
                        onClick={() => mint(10)}
                        type="button"
                        className="claim-btn"
                        disabled={loading || isClaimed}
                      >
                        Mint 10
                      </button>
                    </div>

                  {transactionHash && (
                    <a
                      href={`https://etherscan.io/tx/${transactionHash}`}
                      target="_blank"
                      rel="noreferrer"
                      className="claim-btn"
                    >
                      See transaction
                    </a>
                  )}
                </>
              ) : (
                <button
                  type="button"
                  className="connect-wallet"
                  onClick={connectWallet}
                >
                  Connect Wallet
                </button>
              )}
            </div>
            <img src={Gifer} alt="Gifer" />
          </div>
          <p>eaach 1/777 wwIInn 1.777</p>
        </div>
      </section>
      <Toaster position="top-center" reverseOrder={false} />
    </>
  );
}

export default App;
