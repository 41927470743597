import React, { createContext, useState, useCallback } from "react";
import abi from "../output/abi.json";
import toast from 'react-hot-toast';
import { ethers } from "ethers";

const CONTRACT_ADDRESS = "0xB3Cda7B338AA9C65F24bbe6883aB9df9E630d418";
const CORRECT_NET_ID = 1;

export const DAppContext = createContext(null);

export const DAppProvider = ({ children }) => {
  const [userData, setUserData] = useState(null);
  const [transactionHash, setTransactionHash] = useState("");
  const [loading, setLoading] = useState(false);
  const [isClaimed, setIsClaimed] = useState(false);
  const [contractDetails, setContractDetails] = useState(null);

  const connectToContract = async (provider, signer, userAddress) => {
    try {
      const instance = new ethers.Contract(CONTRACT_ADDRESS, abi, provider);
      const contractWithSigner = instance.connect(signer);
      let details = {};
      
      const {
        isActive,
        mintPrice,
        totalSupply = () => {},
        balanceOf
      } = contractWithSigner;

      const balancer = parseInt(window.Number(await balanceOf(userAddress)));
      const isPublicSaleActive = await isActive();
      const publicETHPrice = ethers.utils.formatEther(await mintPrice());
      const alreadyMinted = await totalSupply();
      details.price = publicETHPrice;


      details = {
        ...details,
        isPublicSaleActive,
        alreadyMinted,
        methods: contractWithSigner,
      };
      setContractDetails(details);

      if(alreadyMinted < 2222 && balancer === 1) {
        setIsClaimed(true);
      }
      setLoading(false);
    } catch (error) {
      console.log(error, "Error");
    }
  }

  const connectBrowserWallet = async () => {
    try {
      setLoading(true);
      const web3Provider = new ethers.providers.Web3Provider(window.ethereum);
      await web3Provider.send("eth_requestAccounts", []);

      const signer = web3Provider.getSigner();
      const accounts = await signer.getAddress();
      const { chainId } = await web3Provider.getNetwork();
  
      if (parseInt(chainId) !== CORRECT_NET_ID)
        return alert("Please change to MainNet");
  
      setUserData({
        account: accounts,
        chainId: Number(chainId),
      });

      await connectToContract(web3Provider, signer, accounts);
    } catch (error) {
      console.log(error, "Error");
      setLoading(false);
    }
    
  };

  const mint = useCallback(async (count) => {
    try {
      setLoading(true);
      const account = userData.account;
      if (!contractDetails) return toast.error(`No instance`);
      if (!account)
        return toast.error(`No account selected. Try reauthenticating`);
      if (!count) return toast.error(`No token count provided.`);
      
      const {
        isActive,
        _mintGoblin,
        mintPrice,
        totalSupply = () => {},
      } = contractDetails.methods;
      const isPublicSaleActive = await isActive();
      const publicETHPrice = await mintPrice();
      const initialCount = await totalSupply();
      let cost = 0;
      
      if(initialCount > 2222) {
        cost = window.BigInt(`${count * publicETHPrice}`)      
      }
      
      const options = { value: cost };

      if (!isPublicSaleActive)
        return toast.error(`Sales has not start yet`);

      const outPut = await _mintGoblin(count, options);
      const alreadyMinted = await totalSupply();
      
      setTransactionHash(outPut.hash)
      setContractDetails({
        ...contractDetails,
        alreadyMinted
      });

      setLoading(true);
      return outPut;
    } catch (error) {
      toast.error(error.message);
      setLoading(false);
    }
  }, [contractDetails, userData]);

  const resetTransactionHash = () => {
    setTransactionHash("");
  };

 
  return (
    <DAppContext.Provider
      value={{
        connectBrowserWallet,
        mint,
        loading,
        transactionHash,
        resetTransactionHash,
        contractDetails,
        userData,
        isClaimed
      }}
    >
      {children}
    </DAppContext.Provider>
  );
};
